var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
// import { RoleService } from "~/services/business-service/role.service";
import { Emit } from "vue-property-decorator";
import clone from "clone";
var CompanyAchieveUpdate = /** @class */ (function (_super) {
    __extends(CompanyAchieveUpdate, _super);
    function CompanyAchieveUpdate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.modifyModel = {
            caseNumber: "",
            batchNumber: "",
            cardNumber: ""
        };
        _this.loading = {
            state: false
        };
        return _this;
    }
    // @Dependencies(RoleService) private roleService: RoleService;
    CompanyAchieveUpdate.prototype.refreshList = function () { };
    CompanyAchieveUpdate.prototype.close = function () { };
    // private rules: any = {
    //   name: [{ required: true, message: "请输入角色名称", trigger: "blur" }]
    // };
    // reset() {
    //   let modifyForm: any = this.$refs["modify-form"];
    //   modifyForm.resetFields();
    // }
    CompanyAchieveUpdate.prototype.refresh = function (obj) {
        this.modifyModel = clone(obj);
    };
    CompanyAchieveUpdate.prototype.commit = function () {
        var _this = this;
        var modifyForm = this.$refs["modify-form"];
        modifyForm.validate(function (valid) {
            if (!valid)
                return false;
            _this.modifyModel.resources = null;
            _this.loading.state = true;
            // this.roleService.updateRole(this.modifyModel, this.loading).subscribe(
            //   data => {
            //     this.$message.success("修改成功!");
            //     this.refreshList();
            //     this.close();
            //   },  ({ msg }) => {
            //   }
            // );
        });
    };
    __decorate([
        Emit("refreshList")
    ], CompanyAchieveUpdate.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], CompanyAchieveUpdate.prototype, "close", null);
    __decorate([
        Getter
    ], CompanyAchieveUpdate.prototype, "isSupperAdmin", void 0);
    CompanyAchieveUpdate = __decorate([
        Component({
            components: {}
        })
    ], CompanyAchieveUpdate);
    return CompanyAchieveUpdate;
}(Vue));
export default CompanyAchieveUpdate;
